import React from "react";
import startCase from "lodash/startCase";

import { SEO } from "gatsby-plugin-seo";
import Layout from "../../components/Layout";

import {
  Section,
  Divider,
  MailSignUp,
  BlogFeed,
  TagsList,
  AuthorSummary,
  BookCtaBanner
} from "../../components/UI";

const BlogFeedPage = ({ feedType = "recent", tag = "" }) => {
  const description =
    feedType === "tag"
      ? `Howard Rachinski's writings on ${startCase(tag)}.`
      : "Howard Rachinski's Blog.";

  const pagePath = feedType === "tag" ? `/tags/${tag}` : "/blog";

  const title = feedType === "tag" ? `Writings on ${startCase(tag)}` : "Blog";
  return (
    <Layout>
      <SEO title={title} description={description} pagePath={pagePath} />
      <BookCtaBanner />
      <Section>
        <div className="flex flex-wrap lg:flex-no-wrap pt-10">
          <div className="w-full lg:w-9/12 lg:pr-8">
            <BlogFeed feedType={feedType} tag={tag} />
          </div>
          <div className="w-full lg:w-3/12 bg-gray-100 px-4 py-6 lg:mt-18 mb-4 lg:self-start">
            <AuthorSummary />
            <Divider className="my-6" />
            <TagsList activeTag={tag} />
            <Divider className="my-6" />
            <MailSignUp />
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default BlogFeedPage;
